import React from "react";
import { graphql, Link } from "gatsby";
import Container from "@layout/container";
import Meta from "../components/seo/Meta";

function ContactUsThankYou(props) {
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `お問合せありがとうございました | Harness 正規販売代理店 DXable`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <div className="hn-container">
          <div className="contactThankWrapper">
            <h1>
              お問い合わせを受け付けました。
              <br />
              後ほど担当者よりご連絡いたします。
            </h1>
            <Link to="/">メインページに戻る</Link>
          </div>
        </div>
      </Container>
    </>
  );
}

export const thankYouPageData = graphql`
  query fetchThankYouPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default ContactUsThankYou;
